import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import { Grid } from "@material-ui/core";
import {
  EXLY_CONNECT_ACTIONS_PRIMARY_CTA_TEXT,
  EXLY_CONNECT_ACTIONS_TITLE,
  LINK_TYPE_FORM_KEY,
  LINKS_TYPES,
} from "./ExlyConnectActionsModal.constants";
import styles from "./ExlyConnectActionModal.module.css";
import classnames from "classnames";
import { Radio } from "@my-scoot/component-library-legacy";
import { Form, useForm } from "react-final-form";
import { useNotifications } from "utils/hooks";
import { getLinkOptionsBasedOnActionType } from "../../utils/ExlyConnectMeetingCard.utils";

export const Type = ({
  type,
  icon,
  label,
  description,
  onClick,
  selected,
  columns,
}) => {
  const form = useForm();
  return (
    <Grid item sm={columns} className={styles.type_container}>
      <Radio
        checked={selected}
        onClick={() => form.change(LINK_TYPE_FORM_KEY, type)}
      />
      <div
        className={classnames(styles.type, selected && styles.type_selected)}
        onClick={onClick}
      >
        <img src={icon} />

        <div>
          <div className={styles.type_label}>{label}</div>
          <div className={styles.type_description}>{description}</div>
        </div>
      </div>
    </Grid>
  );
};

const ExlyConnectActionModal = ({ open, type, links, onSubmit, onClose }) => {
  const { notify } = useNotifications();

  const handleFormSubmit = (values) => {
    //fetching and passing the meeting link to be taken actions on.
    onSubmit(links[values[LINK_TYPE_FORM_KEY]]);
    onClose();
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={{ [LINK_TYPE_FORM_KEY]: LINKS_TYPES.PROTECTED }}
    >
      {({ handleSubmit, form, values: { [LINK_TYPE_FORM_KEY]: linkType } }) => {
        const handleFormSubmit = () => {
          if (!linkType) return notify("Please select action type.", "error");
          handleSubmit();
        };

        return (
          <ExlyModal
            open={open}
            onClose={onClose}
            title={EXLY_CONNECT_ACTIONS_TITLE[type]}
            onPrimaryBtnClick={handleFormSubmit}
            primaryBtnFullWidth
            primaryBtnText={EXLY_CONNECT_ACTIONS_PRIMARY_CTA_TEXT[type]}
            showSecondaryBtn={false}
            className={styles.modal_root}
            modal_props={{
              footerBtnsPosition: "space-between",
            }}
          >
            <Grid container spacing={5}>
              {getLinkOptionsBasedOnActionType(type).map(
                (option) =>
                  !option.hide && (
                    <Type
                      key={option.type}
                      selected={linkType === option.type}
                      onClick={() =>
                        form.change(LINK_TYPE_FORM_KEY, option.type)
                      }
                      columns={12}
                      {...option}
                    />
                  )
              )}
            </Grid>
          </ExlyModal>
        );
      }}
    </Form>
  );
};

export default ExlyConnectActionModal;

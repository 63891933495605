// if: lines of code > 300
// then: breakdown this file and move to src/features/ExlyConnect/utils folder

import SessionStorageConstants from "constants/SessionStorage.constants";
import { app_route_keys } from "constants/urlPaths";
import { navigateTo } from "utils/urlUtils";

export const onTabChange = (_, value) => {
  sessionStorage.setItem(
    SessionStorageConstants.ACTIVE_EXLY_CONNECTED_TAB,
    value
  );
  navigateTo(app_route_keys.exly_connect, { args: value });
  window.location.reload();
};

export const FILTER_KEYS = {
  offering_title: {
    label: "Offering title",
    key: "title",
  },
  offering_type: {
    label: "Offering type",
    key: "type",
  },
};

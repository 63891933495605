import { dataProvider } from "data";
import { useGetReduxListingDetails } from "./useGetReduxListingDetails";
import { listing_apis } from "../Listings.constants";
import { apiMethods } from "data/api.constants";

/**
 *
 * @returns listing details if present in redux else api call to fetch data
 */
export const useFetchListingDetails = () => {
  const { getReduxListingDetails } = useGetReduxListingDetails();
  /**
   *
   * @param {object} arg
   * @param {boolean} arg.upcoming_session_details - when passed true, returns upcoming_session_start and upcoming_session_end date time along with other listing details
   */
  const fetchListingDetails = async ({
    listingUuid,
    forceFetch = false,
    params = undefined,
  }) => {
    if (!listingUuid) throw new Error("listingUuid missing!");
    let listingingDetails = getReduxListingDetails(listingUuid);
    if (!listingingDetails || forceFetch === true) {
      const response = await dataProvider.custom_request(
        `${listing_apis.get_listing_details}/${listingUuid}`,
        apiMethods.GET,
        params
      );
      listingingDetails = response?.data?.event;
    }
    return listingingDetails;
  };

  return { fetchListingDetails };
};

import { FILTER_TYPES } from "common/Components/ExlyTable/ExlyTableFilters/filtersConstants";
import { FILTER_KEYS } from "./ScheduledExlyConnect.constants";
import {
  SCHEDULE_OFFERING_TYPE_NAMES,
  schedule_types_ids,
} from "constants/schedule";

export const getFilterConfig = () => [
  {
    source: FILTER_KEYS.offering_type.key,
    placeholder: FILTER_KEYS.offering_type.label,
    options: Object.entries(SCHEDULE_OFFERING_TYPE_NAMES)
      .filter(([key]) => parseInt(key) !== schedule_types_ids.webinar)
      .map(([key, value]) => ({
        label: value,
        value: key,
      })),
    type: FILTER_TYPES.dropdown,
    disableContains: true,
  },
  {
    source: FILTER_KEYS.offering_title.key,
    placeholder: FILTER_KEYS.offering_title.label,
    type: FILTER_TYPES.input,
    disableContains: true,
  },
  {
    source: FILTER_KEYS.scheduled_from.key,
    placeholder: FILTER_KEYS.scheduled_from.label,
    type: FILTER_TYPES.date,
  },
  {
    source: FILTER_KEYS.scheduled_to.key,
    placeholder: FILTER_KEYS.scheduled_to.label,
    type: FILTER_TYPES.date,
  },
];

export const tableChipsFormatter = () => (filter, value) => {
  switch (filter) {
    case FILTER_KEYS.offering_title.key: {
      return `${FILTER_KEYS.offering_title.label}: ${value}`;
    }
    case FILTER_KEYS.offering_type.key: {
      return `${FILTER_KEYS.offering_type.label}: ${SCHEDULE_OFFERING_TYPE_NAMES[value]}`;
    }
    case FILTER_KEYS.scheduled_from.key: {
      return `${FILTER_KEYS.scheduled_from.label}: ${value}`;
    }
    case FILTER_KEYS.scheduled_to.key: {
      return `${FILTER_KEYS.scheduled_to.label}: ${value}`;
    }
    default:
      return undefined;
  }
};

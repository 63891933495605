export const FILTER_KEYS = {
  offering_title: {
    label: "Offering title",
    key: "title",
  },
  offering_type: {
    label: "Offering type",
    key: "type",
  },

  scheduled_from: {
    label: "Scheduled from",
    key: "start_datetime__start_range",
  },

  scheduled_to: {
    label: "Scheduled to",
    key: "end_datetime__end_range",
  },
};

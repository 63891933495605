export const EXLY_CONNECT_ACTION_TYPES = {
  COPY_CUSTOMER_LINK: "COPY_CUSTOMER_LINK",
  BROADCAST_MEETING_LINK: "BROADCAST_MEETING_LINK",
};

export const EXLY_CONNECT_ACTION_TYPES_BASED_OPTION_HEADER = {
  [EXLY_CONNECT_ACTION_TYPES.COPY_CUSTOMER_LINK]: "Copy",
  [EXLY_CONNECT_ACTION_TYPES.BROADCAST_MEETING_LINK]: "Broadcast",
};

export const EXLY_CONNECT_ACTIONS_TITLE = {
  [EXLY_CONNECT_ACTION_TYPES.COPY_CUSTOMER_LINK]: "Customer Link",
  [EXLY_CONNECT_ACTION_TYPES.BROADCAST_MEETING_LINK]: "Broadcast Meeting Link",
};

export const EXLY_CONNECT_ACTIONS_PRIMARY_CTA_TEXT = {
  [EXLY_CONNECT_ACTION_TYPES.COPY_CUSTOMER_LINK]: "Copy Link",
  [EXLY_CONNECT_ACTION_TYPES.BROADCAST_MEETING_LINK]: "Broadcast",
};

export const LINKS_TYPES = {
  PROTECTED: "PROTECTED",
  OPEN: "OPEN",
};

export const intialExlyConnectActionModalProps = {
  open: false,
  type: "",
  links: {
    [LINKS_TYPES.PROTECTED]: "",
    [LINKS_TYPES.OPEN]: "",
  },
};

export const LINK_TYPE_FORM_KEY = "meeting_link_type";

import { FILTER_TYPES } from "common/Components/ExlyTable/ExlyTableFilters/filtersConstants";
import { FILTER_KEYS } from "./OndemandExlyConnect.constants";
// import { schedule_type_names } from "constants/schedule";

export const getFilterConfig = () => [
  // keeping commented; can be added later
  // {
  //   source: FILTER_KEYS.offering_type.key,
  //   placeholder: FILTER_KEYS.offering_type.label,
  //   options: Object.entries(schedule_type_names).map(([key, value]) => ({
  //     label: value,
  //     value: key,
  //   })),
  //   type: FILTER_TYPES.dropdown,
  //   disableContains: true,
  // },
  {
    source: FILTER_KEYS.offering_title.key,
    placeholder: FILTER_KEYS.offering_title.label,
    type: FILTER_TYPES.input,
    disableContains: true,
  },
];

export const tableChipsFormatter = () => (filter, value) => {
  switch (filter) {
    case FILTER_KEYS.offering_title.key: {
      return `${FILTER_KEYS.offering_title.label}: ${value}`;
    }
    // keeping commented; can be added later
    // case FILTER_KEYS.offering_type.key: {
    //   return `${FILTER_KEYS.offering_type.label}: ${schedule_type_names[value]}`;
    // }
    default:
      return undefined;
  }
};

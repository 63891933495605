import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { EXLY_CONNECT_APIS } from "features/ExlyConnect/ExlyConnect.api";
import {
  EXLY_CONNECT_ACTION_TYPES_BASED_OPTION_HEADER,
  LINKS_TYPES,
} from "../modules/ExlyConnectActionModal/ExlyConnectActionsModal.constants";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

export const fetchCustomerZoomLinkApi = (listingUuid) => {
  return dataProvider.custom_request(
    EXLY_CONNECT_APIS.fetch_customer_zoom_link,
    apiMethods.GET,
    { listing_uuid: listingUuid }
  );
};

export const broadcastMeetingLinkApi = (slotUuid, zoomLink) => {
  return dataProvider.custom_request(
    EXLY_CONNECT_APIS.broadcast_exly_con_reminders,
    apiMethods.GET,
    { slot_uuid: slotUuid, zoom_link: zoomLink }
  );
};

/**
 * Action Type specifies that either its broadcast action or copy
 * action so on the basis of actionType labels are accordingly generated.
 * */
export const getLinkOptionsBasedOnActionType = (actionType) => [
  {
    type: LINKS_TYPES.PROTECTED,
    label: `${EXLY_CONNECT_ACTION_TYPES_BASED_OPTION_HEADER[actionType]} protected link`,
    description:
      "Only meeting customers can join the meeting. Webpage login will be required.",
    icon: getS3AssetCDNUrl("assets/vectors/exly_connect/protected_link.svg"),
  },
  {
    type: LINKS_TYPES.OPEN,
    label: `${EXLY_CONNECT_ACTION_TYPES_BASED_OPTION_HEADER[actionType]} open link`,
    description:
      "Anyone who has this link can join the meeting. Login will not be required.",
    icon: getS3AssetCDNUrl("assets/vectors/exly_connect/open_link.svg"),
  },
];

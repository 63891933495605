import { listings_redux_status_wise_keys } from "../Listings.constants";
import { useGetReduxListings } from "./useGetReduxListings";

export const useGetReduxListingDetails = () => {
  const { listings } = useGetReduxListings();
  const getReduxListingDetails = (listingUuid) => {
    let listing;

    for (let key of Object.values(listings_redux_status_wise_keys)) {
      listing = listings[key].data.find((item) => item.uuid === listingUuid);
      if (listing) break;
    }
    return listing;
  };
  return { getReduxListingDetails };
};

import React from "react";
import { useLocation } from "react-router-dom";
import { TipsAndUpdates } from "common/Components/TipsAndUpdates/TipsAndUpdates";
import { Tabs } from "@my-scoot/component-library-legacy";
import {
  EXLY_CONNECT_LEARN_MORE_HREFS,
  EXLY_CONNECT_TABS,
  EXLY_CONNECT_TITLE,
  exly_connect_error_codes,
} from "features/ExlyConnect/ExlyConnect.constants";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "common/Components/ExlyTable";
import { ExlyConnectMeetingCard } from "features/ExlyConnect/modules/ExlyConnectMeetingCard";
import {
  getFilterConfig,
  tableChipsFormatter,
} from "./ScheduledExlyConnect.tableConfig";
import { FILTER_KEYS } from "./ScheduledExlyConnect.constants";
import { useListController } from "react-admin";
import { Header } from "common/Components/Header";
import { onTabChange } from "features/ExlyConnect/ExlyConnect.utils";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import { format } from "date-fns";
import { DATE_FILTER } from "constants/date-fns/dateTime.constant";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  useNotifications,
  notification_color_keys,
} from "utils/hooks/useNotifications";
import { logError } from "utils/error";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

export const ScheduledExlyConnect = withComponentLibraryTheme((props) => {
  const location = useLocation();
  const { notify } = useNotifications();
  const isDesktop = useDesktopMediaQuery();
  const { setFilters } = useListController({
    ...props,
    perPage: DEFAULT_PER_PAGE,
  });
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.EXLY_CONNECT,
    successCallback: () => handleFeatureOnboardChange(true),
  });
  useAppLayoutControl({ control: { bottomNav: !isDesktop } });

  React.useEffect(() => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const meetingRedirectError =
        queryParams.get(exly_connect_error_codes.key) || "";
      if (meetingRedirectError === exly_connect_error_codes.value) {
        notify(
          "Note: You'll need to launch meeting again to join!",
          notification_color_keys.error
        );
      }
    } catch (err) {
      logError({
        error: err,
        occuredAt:
          "src/features/ExlyConnect/pages/ScheduledExlyConnect/index.js",
        when: "trying to get url params",
      });
    }
    setFilters({
      [FILTER_KEYS.scheduled_from.key]: format(new Date(), DATE_FILTER),
      [FILTER_KEYS.scheduled_to.key]: format(new Date(), DATE_FILTER),
    });
  }, []);

  return (
    <>
      <Header
        title={EXLY_CONNECT_TITLE}
        classNames={{
          container: `px-4 `,
        }}
        right={
          <LearnMoreCta href={EXLY_CONNECT_LEARN_MORE_HREFS.EXLY_CONNECT} />
        }
      />
      <div className={`p-4`}>
        <TipsAndUpdates
          className="mb-4 small"
          iconSize="18px"
          tip={
            <span>
              It is advised to launch the meeting 5 minutes early for all the
              participants to join prior.
            </span>
          }
        />

        <div className={`mb-4`}>
          <Tabs
            tabs={Object.values(EXLY_CONNECT_TABS)}
            value={EXLY_CONNECT_TABS.scheduled.value}
            onChange={onTabChange}
            tabVariant="outlined"
          />
        </div>

        <ExlyTable
          ra_props={{
            ...props,
            perPageOptions: null,
          }}
          noExportButton
          tableFilters={getFilterConfig()}
          tableChipsFormatter={tableChipsFormatter()}
          layoutProps={{
            showFixedBars: true,
            noShadow: true,
            paddingBottom: "32px",
            paddingMobile: "0px",
          }}
          primaryKey="id"
          gridView
          gridWidth={{ desktop: "100%", mobile: "100%" }}
          GridComponent={({ record }) =>
            ExlyConnectMeetingCard({
              data: record,
            })
          }
          exlyFilteredEmptyStateProps={
            isDesktop && {
              customEmptyState: (
                <ExlyEmptyState
                  isLoading={isFeatureFlagLoading}
                  title="No results found"
                  description="Try different keywords"
                  videoSrc={EXLY_CONNECT_LEARN_MORE_HREFS.EXLY_CONNECT_VIDEO}
                  playerProps={{
                    playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
                  }}
                  secondaryCtaProps={{
                    variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
                    learnMoreHref: EXLY_CONNECT_LEARN_MORE_HREFS.EXLY_CONNECT,
                  }}
                  className="my-4"
                />
              ),
            }
          }
        />
      </div>
    </>
  );
});
